import config from "../../../config";

const ja = {
    // HEADER PAGE
    CONNECT : "接続",
    WRONG_NETWORK : "間違ったネットワーク",

    //MAIN PAGE
    SVC_TOKEN_POOL : "SVCトークン・プール",
    CIRCULATION_SUPPLY : "循環供給",
    OF_SUPPLY : "総供給量に占める割合",
    OF : "の",
    TOTAL_LOCKED_SVC : "SVCロック合計",
    TOTAL_SVC_STAKED : "SVCの総ステーク数",
    POOL_APY : "プールAPY",
    STAKE_SVC : "プールにSVCを張り付ける",
    BUY_ON_B2Z : "B2ZでSVCを購入する",
    STAKING_SVC : "SVCを賭けて、SVCを獲得する",
    CURRENT_SVC_BAL : "現在のSVCの残高",
    ESTIME_APY : "予想されるAPY",
    AVAILABLE_DEPOSIT : "預け入れ可能",
    MAX : "マックス",
    APPROVE: "承認",
    STAKE : "ステイク",
    UNCLAIMED_REWARDS : "未請求の報酬",
    CLAIM_REWARDS : "褒賞を受け取る",
    TOTAL_STAKED : "合計",
    WITHDRAW_ALL : "すべて取り消す",
    MILLION : "百万",
    BILLION : "億",
    HOME : "Home",
    PG_NOFOUND : "ページが見つかりません",
    CLICK_TOHOME : "トップページに戻るには、こちらをクリックしてください。",
    STAKE_AMT : "ステーク量",
    STAKE_DATE : "開始日",
    REWARD_AMT : "賞金額",
    TX_HASH : "トランザクション・ハッシュ",
    TX_COPIED : "トランザクションハッシュのクリップボードへのコピー",
    CPY_HASH : "トランザクションハッシュのコピー",
    CCC_IMPORT : "インポートCCC",
    NO_RECORDS : 'レコードが見つかりません',
    ROWS : "行 :",
    S_NO : "いいえ",
    OF : "の",


    //FOOTER
    COPYRIGHT : "著作権について ",
    RIGHTS : "無断転載禁止",
    DISCLAIMER : "免責事項",
    DISCLAIMER_CONTENT : "本ウェブサイトまたはその他の公式チャネルに掲載されているすべての情報は、情報提供のみを目的としており、機関投資家および洗練された個人投資家のみを対象としています。今後提供されるサービスは、それに関する法的契約の条件に従うものとします。本サイトのいかなる情報も、セブン・チェーン・ネットワークまたはその他の者による投資の推奨を意味するものではありません。セブン・チェーン・ネットワークおよびその関連サービスは、米国からの個人に対して提供されるものではありません。セブンチェーンネットワークは、Polkadot上でデータ経済を動かすクロスチェーンプラットフォームの構築を目指しています。",

    //MESSAGES - STAKING:
    msg1 : "0を賭けることはできません。",
    msg2 : "残高制限を超えることはできません",
    msg3 : "承認されました",
    msg4 : "承認にエラーが発生しました、もう一度やり直してください",
    msg5 : "報酬の請求に成功しました",
    msg6 : "賭け金請求成功",
    msg7 : "ステイクドSVCの取得に失敗しました。",
    msg8 : "ステーキング量と同じにする必要があります。",
    msg9 : "ステーキングに成功",
    msg10 : "あなたの賭け金は、次のとおりです。 ",
    STAKING_REJECTED: "ステーキングが拒否されました、もう一度試してください",
    MIN_STAKE_ERROR: "最低賭け金額",
    APPROVE_REJECT_ERROR: "承認が拒否されました、もう一度やり直してください",
    APPROVE_FAILED_ERROR: "承認に失敗しました、もう一度やり直してください",
    USER_REJECTED_ERROR: "ユーザーが賭け金の引き出しを拒否しました。",
    USER_REJECTED_CLAIM_REWARDS: "ユーザーが請求した報酬の引き出しが拒否されました。",
    APPROVE_TRANSACTION: "接続されたウォレットからの取引を承認してください。",
    RECEIVED: "received",
    GAME_TOKEN_REWARD_TEXT: config.GAME_TOKEN_REQUIREMENT +"以上の SVC をステークすると、同量の"+config.GAME_TOKEN + "を受け取ることができます",
    GAME_TOKEN_RECEIVED: "あなたが受け取ったもの",
    GAME_TOKEN_RECEIVED2: " 特典の履歴はこちらをご覧ください",
  }

export default ja;
