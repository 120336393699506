import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import { AppBar, CssBaseline, Toolbar } from '@material-ui/core';

// third-party

import Header from './Header';
import Footer from './Footer';

// style constant
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'

    },
    appBar: {
        backgroundColor: "#ec890000"
    },

    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        [theme.breakpoints.up('md')]: {
            paddingLeft: '50px',
            paddingRight: '50px'
        }
    }
}));

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children }) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {/* <CssBaseline /> */}
            {/* header */}
            <AppBar
                enableColorOnDark
                position="relative"
                elevation={0}
                className={classes.appBar}
            >
                <Toolbar>
                    <Header />
                </Toolbar>
            </AppBar>


            {/* main content */}
            <main
                className={classes.content}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        flexGrow: 1
                    }}
                >
                    {children}
                </div>
            </main>
            <Footer />
        </div>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node
};

export default MainLayout;
