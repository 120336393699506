import React,{ useState  , useEffect} from 'react';
import { Button, useMediaQuery, useTheme  } from "@material-ui/core";
import { ConnectButton as RainbowConnectButton  } from "@rainbow-me/rainbowkit";
import { makeStyles } from "@material-ui/styles";
import Select from "react-select";
import { language } from "../../pages/language";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import Drawer from "@mui/material/Drawer";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import $ from 'jquery'


  

export const InPageConnectButton = () => {


    const options = [
        { value: "English", label: "EN" },
        { value: "Japanese", label: "日本語" },
      ];
    
    const [select, setSelectedOption] = useState(options[0].value);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [mobileScroll , setMobileScroll] = useState(false);
    const [windowWidth , setWindowWidth] = useState(getWindowWidth())

    // const matches = useMediaQuery('(min-width:900px)');

    useEffect(() => {
        function handleWindowResize() {
            setWindowWidth(getWindowWidth());
          }
      
          window.addEventListener('resize', handleWindowResize);
      
          return () => {
            window.removeEventListener('resize', handleWindowResize);
          };
    }, [])

    function getWindowWidth() {
        const {innerWidth} = window;
        return {innerWidth};
      }

      const useStyle = makeStyles(() => ({
        button: {
            width: '155px',
            height: '40px',
            borderRadius: '20px',
            fontFamily: 'square',
            color:  windowWidth.innerWidth < '900' ? "black" : "white",
            backgroundColor: windowWidth.innerWidth < '900' ? "#FFF" : "transparent",
            border : windowWidth.innerWidth < '900' ?  "1px solid black" :'1px solid white',
            boxShadow: 0,
            '&:hover': {
                backgroundColor: windowWidth.innerWidth < '900' ? "#FFF" : "transparent",
            }
        } , 
       
    }));
    
    
        const colourStyles = {
            placeholder: (styles) => ({
                ...styles,
                color: mobileScroll ? 'black' : '#FFF'
            }),
            control: (styles) => ({
                ...styles,
                backgroundColor: "transparent",
                borderRadius: '20px',
                width: '103px',
                marginTop :windowWidth.innerWidth < '900' ? "7px" :"0px",
                height: '40px',
                fontFamily: 'square',
                color: "#FFF",
            }),
            singleValue: (provided) => ({
                ...provided,
                color: '#FFF'
            }),
            option: (styles, { isDisabled }) => {
                return {
                    ...styles,
                    background: "transparent",
                    width:'100%',
                    color: "black",
                    cursor: isDisabled ? "not-allowed" : "default"
                };
            },
            
        };

    $(window).on("scroll", function() {
        if($(window).scrollTop() > 30 && windowWidth.innerWidth < '900') {
            setMobileScroll(true)
        } else {
           setMobileScroll(false)
        }
    });


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
      };
    
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
      };


    const theme = useTheme();
    const classes = useStyle();


    var localStorageLang = localStorage.getItem("lang");
    if (localStorageLang === null) {
      localStorageLang = "Japanese";
    }

    const handleChange = (SelectedOption) => {
        window.location.reload(true)
        setSelectedOption(SelectedOption.value);
        localStorage.setItem("lang", SelectedOption.value);
        window.scrollTo(0,0);
      };


    return (
        <RainbowConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                mounted
            }) => {
                return (
                    <div
                        {...(!mounted && {
                            'aria-hidden': true,
                            'style': {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none'
                            }
                        })}
                    >
                        {(() => {
                            if (!mounted || !account || !chain) {
                                return (
                                    <>
                                    <div style={{ display: 'flex', gap: 12 }}>
                                    {/* Over all display of Language Select */}

                                    <Select
                                            styles={colourStyles}
                                            value={select.value}
                                            width='200px'
                                            options={options}
                                            onChange={handleChange}
                                            placeholder={localStorageLang == "Japanese" ? "日本語" : "EN"}
                                            className="select-box-translation"
                                            
                                        />
                                    {/* This Box Component defines if not connected and what buttons to display in Desktop Mode */}
                                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }} }>
                                                <Button className={classes.button} onClick={openConnectModal} variant='contained'>
                                                     {language[localStorageLang].CONNECT}
                                                 </Button>
                                     </Box>
                                    {/* This Container Component defines if not connected  and what buttons to display in Mobile Mode */}
                                    <Container maxWidth="xl">
                                    <Toolbar disableGutters>
                                        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleOpenNavMenu}
                                            style={{color : mobileScroll ? "black ":"inherit"}}
                                            // color="inherit"
                                          >
                                            <MenuIcon />
                                          </IconButton>
                                          <Menu
                                                id="menu-appbar"
                                                anchorEl={anchorElNav}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "left"
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "left"
                                                }}
                                                open={Boolean(anchorElNav)}
                                                onClose={handleCloseNavMenu}
                                                sx={{
                                                  display: { xs: "block", md: "none" },
                                                }}
                                              >
                                                  <MenuItem onClick={handleCloseNavMenu}>
                                                    <Typography textAlign="center" component="span">
                                                        <Button className={classes.button} onClick={openConnectModal} variant='contained'>
                                                         {language[localStorageLang].CONNECT}
                                                        </Button>
                                                    </Typography>
                                                  </MenuItem>
                                              </Menu>
                                         </Box>
                                        </Toolbar>
                                     </Container>
                                     </div>
                                    </>
                                    
                                );
                            }

                            if (chain.unsupported) {
                                return (
                                    <Button className={classes.button} onClick={openChainModal} variant="contained">
                                        {language[localStorageLang].WRONG_NETWORK}
                                    </Button>
                                );
                            }

                            return (
                                <div style={{ display: 'flex', gap: 12 }}>
                                     {/* Over all display of Language Select */}
                                     <Select
                                      styles={colourStyles}
                                      value={select.value}
                                      placeholder={localStorageLang == "Japanese" ? "日本語" : "EN"}
                                      options={options}
                                      onChange={handleChange}
                                      className="select-box-translation"
                                    />
                                    {/* This Box Component defines if connection and what buttons to display in Desktop Mode */}
                                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex"} }}>
                                         <Button
                                            onClick={openChainModal}
                                            style={{ display: 'flex', alignItems: 'center' }}
                                            className={classes.button}
                                            variant='contained'
                                        >
                                            {chain.name}
                                        </Button>
                                        <Button onClick={openAccountModal} className={classes.button} variant='contained' style={{marginLeft:10}}>
                                            {account.displayName}
                                        </Button>
                                     </Box>
                                     {/* This Container defines what buttons to display in mobile mode after successfull connection */}
                                     <Container maxWidth="xl">
                                    <Toolbar disableGutters >
                                        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                                        <IconButton
                                            size="large"
                                            aria-label="account of current user"
                                            aria-controls="menu-appbar"
                                            aria-haspopup="true"
                                            onClick={handleOpenNavMenu}
                                            style={{color : mobileScroll ? "black ":"inherit"}}
                                            // color="inherit"
                                          >
                                            <MenuIcon  />
                                          </IconButton>
                                          <Menu
                                                id="menu-appbar"
                                                anchorEl={anchorElNav}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "left"
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                  vertical: "top",
                                                  horizontal: "left"
                                                }}
                                                open={Boolean(anchorElNav)}
                                                onClose={handleCloseNavMenu}
                                                sx={{
                                                  display: { xs: "block", md: "none" }
                                                }}
                                                // PaperProps={{
                                                //     style: {
                                                //         background : 'white',
                                                //     }}}
                                                classes={{backgroundColor : 'white' , paddingTop : 0 , paddingBottom : 0}}
                                              >

                                                 {/* here earlier i added color black */}
                                                  <MenuItem onClick={handleCloseNavMenu} > 
                                                    <Typography textAlign="center" component="span">
                                                    <Button
                                                       onClick={openChainModal}
                                                       style={{ display: 'flex', alignItems: 'center', justifyContent:'center' }}
                                                       className={classes.button}
                                                       variant='contained'
                                                   >
                                                       {chain.name}
                                                   </Button>
                                                   <div className='spacer-single'></div>
                                                   <Button onClick={openAccountModal}  className={classes.button} variant='contained'>
                                                      {account.displayName}
                                                     </Button>
                                                    </Typography>
                                                  </MenuItem>
                                              </Menu>
                                         </Box>
                                        </Toolbar>
                                     </Container>
                                    {/* {
                                        upXs &&
                                        <Button
                                            onClick={openChainModal}
                                            style={{ display: 'flex', alignItems: 'center' }}
                                            className={classes.button}
                                            variant='contained'
                                        >
                                            {chain.name}
                                        </Button>
                                    }
                                    <Button onClick={openAccountModal} className={classes.button} variant='contained'>
                                        {account.displayName}
                                      
                                    </Button>
                                     */}
                                   
                                </div>
                            );
                        })()}
                    </div>
                );
            }}
        </RainbowConnectButton.Custom>
    );
};

