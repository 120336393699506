import React, { lazy } from 'react';
import { BrowserRouter as Router , Route, Switch, useLocation , Redirect } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../components/Loadable';
import { useProvider } from 'wagmi';

// dashboard routing
const Home = Loadable(lazy(() => import('../views/Home')));
const Staking = Loadable(lazy(() => import('../views/Staking')));
const ErrorPage = Loadable(lazy(() => import('../views/ErrorPage')))
const History = Loadable(lazy(() => import('../views/History')))




//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    // const { isUserRegistered } = useApi();
    // useEffect(() => {
    //     if (isConnected) {
    //         isUserRegistered(address, provider);
    //     }
    // }, [isConnected, address, provider]);

    return (
        <Route path={['/']}>
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <Route exact path="/" component={Home} />
                    <Route path="/stake" component={Staking} />
                    <Route path="/user-history" component={History} />
                    <Route path='*' component={ErrorPage} element={<Redirect to='/error/404' />} />
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
